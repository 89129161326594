<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">
        De-Selected Strategies
      </h5>
      <button
        type="button"
        class="btn-close"
        :class="{ 'btn-close-white': isDarkMode }"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div>
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="deselected_startegie in filteredStrategies"
            v-bind:key="deselected_startegie"
          >
            <input
              class="form-check-input me-1"
              type="checkbox"
              :value="deselected_startegie"
              :id="deselected_startegie"
              @click="showDeSelectedStrategies(deselected_startegie)"
            />
            <label
              class="form-check-label stretched-link"
              :for="deselected_startegie"
              >{{ deselected_startegie }}</label
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- Save button fixed at the bottom -->
    <div class="offcanvas-footer fixed-bottom">
      <div class="w-100 p-3 justify-content-end d-flex">
        <button
          @click="resetStrats"
          class="btn btn-outline-custom my-2 mx-2 margin-left"
        >
          Show All
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid my-3">
    <div class="header">
      <h2 style="flex: 1">Dashboard Strategies</h2>
      <!-- Adding search bar -->
      <div class="container col-md-2 float-end">
        <input
          type="text"
          class="form-control"
          v-model="searchStrats"
          placeholder="Search..."
          id="searchStrats"
          @keyup="searchStratsFn"
        />
      </div>
      <!-- <button @click="resetStrats" class="btn btn-outline-custom my-2">
        Show All
      </button> -->
      <button
        class="btn btn-outline-custom my-2"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
      >
        Hidden Strategies
      </button>

      <button @click="toggleDarkMode" class="btn btn-toggle-dark-mode my-2">
        {{ isDarkMode ? "Light Mode" : "Dark Mode" }}
      </button>
    </div>
    <div class="header">
      <button
        class="btn btn-outline-custom my-2"
        type="button"
        @click="selectTeamFilter(null, null)"
        :class="{ active: team_tab == null && process_type == null }"
      >
        All
      </button>
      <button
        class="btn btn-outline-custom my-2"
        type="button"
        @click="selectTeamFilter(null, 'strategy')"
        :class="{ active: team_tab == null && process_type == 'strategy' }"
      >
        strategy
      </button>
      <button
        class="btn btn-outline-custom my-2"
        type="button"
        @click="selectTeamFilter(null, 'paper')"
        :class="{ active: team_tab == null && process_type == 'paper' }"
      >
        paper
      </button>
      <template v-for="team in team_list" v-bind:key="team">
        <button
          class="btn btn-outline-custom my-2"
          type="button"
          @click="selectTeamFilter(team, 'strategy')"
          :class="{ active: team_tab == team && process_type == 'strategy' }"
        >
          {{ team }}
        </button>
      </template>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered custom-table table-hover strats">
        <thead>
          <tr>
            <th scope="col">
              <i class="bi bi-app-indicator"></i>
            </th>
            <th scope="col">SR.No</th>
            <th scope="col" @click="sort('name')">process</th>
            <th scope="col" @click="sort('machine')">server</th>
            <th scope="col" @click="sort('team')">team</th>
            <th scope="col" @click="sort('time')">time</th>
            <th scope="col" @click="sort('net_pnl')">net pnl</th>
            <th scope="col" @click="sort('realised')">realised</th>
            <th scope="col" @click="sort('unrealised')">unrealised</th>
            <th scope="col" @click="sort('cost')">cost</th>
            <th scope="col" @click="sort('turnover')">turnover</th>
            <th scope="col" @click="sort('net_exposure')">net exposure</th>
            <th scope="col" @click="sort('gross_exposure')">gross exposure</th>
            <th scope="col" @click="sort('open_long_exposure')">
              open long exposure
            </th>
            <th scope="col" @click="sort('open_short_exposure')">
              open short exposure
            </th>
            <th scope="col" @click="sort('net_open_amount')">
              net open amount
            </th>
            <th scope="col" @click="sort('gross_open_amount')">
              gross open amount
            </th>
            <th scope="col" @click="sort('margin')">margin</th>
            <th scope="col" @click="sort('max_margin')">max margin</th>
            <th scope="col" @click="sort('mode')">mode</th>
            <th scope="col">chart</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(log, index) in sortedPnl"
            :key="log.id"
            :class="rowClass(log)"
          >
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="visibleLogs"
                :value="log.name"
                checked
                @click="delayedLoopTableAndStore(log)"
              />
            </td>
            <td>{{ index + 1 }}</td>
            <td class="fw-bold">
              <router-link :to="log.get_absolute_url">{{
                log.name
              }}</router-link>
            </td>
            <td>{{ log?.machine }}</td>
            <td>{{ log?.team }}</td>
            <td>{{ formatTime(log.time, 0) }}</td>
            <td>{{ formatNumber(log.net_pnl, 2) }}</td>
            <td>{{ formatNumber(log.realised, 2) }}</td>
            <td>{{ formatNumber(log.unrealised, 2) }}</td>
            <td>{{ formatNumber(log.cost, 2) }}</td>
            <td>{{ formatNumber(log.turnover, 2) }}</td>
            <td>{{ formatNumber(log.net_exposure, 2) }}</td>
            <td>{{ formatNumber(log.gross_exposure, 2) }}</td>
            <td>{{ formatNumber(log.open_long_exposure, 2) }}</td>
            <td>{{ formatNumber(log.open_short_exposure, 2) }}</td>
            <td>{{ formatNumber(log.net_open_amount, 2) }}</td>
            <td>{{ formatNumber(log.gross_open_amount, 2) }}</td>
            <td>{{ formatNumber(log.margin, 2) }}</td>
            <td>{{ formatNumber(log.max_margin, 2) }}</td>
            <td>{{ log.mode }}</td>
            <td>
              <router-link :to="`${log.name}/all-chart/`">
                <i class="bi bi-graph-up-arrow"></i>
              </router-link>
            </td>
          </tr>
          <tr>
            <th class="text-right font-weight-bold tbody-1st">Total</th>
            <th class="fw-bold">
              <!-- <i class="bi bi-medium"></i> -->
              <i
                @click="combinedIndPnlChartRoute"
                class="bi bi-sign-turn-right-fill"
              ></i>
            </th>
            <th class="fw-bold"><i class="bi bi-medium"></i></th>
            <th class="fw-bold"><i class="bi bi-medium"></i></th>
            <th class="fw-bold"><i class="bi bi-medium"></i></th>
            <th class="fw-bold"><i class="bi bi-medium"></i></th>
            <th class="font-weight-bold">{{ formatNumber(totalNetPnl, 2) }}</th>
            <th class="font-weight-bold">
              {{ formatNumber(totalRealised, 2) }}
            </th>
            <th class="font-weight-bold">
              {{ formatNumber(totalUnrealised, 2) }}
            </th>
            <th class="font-weight-bold">{{ formatNumber(totalCost, 2) }}</th>
            <th class="font-weight-bold">
              {{ formatNumber(totalTurnover, 2) }}
            </th>
            <th class="font-weight-bold">
              {{ formatNumber(totalNetExposure, 2) }}
            </th>
            <th class="font-weight-bold">
              {{ formatNumber(totalGrossExposure, 2) }}
            </th>
            <th class="font-weight-bold">
              {{ formatNumber(totalOpenLongExposure, 2) }}
            </th>
            <th class="font-weight-bold">
              {{ formatNumber(totalOpenShortExposure, 2) }}
            </th>
            <th class="font-weight-bold">
              {{ formatNumber(totalNetOpenAmount, 2) }}
            </th>
            <th class="font-weight-bold">
              {{ formatNumber(totalGrossOpenAmount, 2) }}
            </th>
            <th class="font-weight-bold">{{ formatNumber(totalMargin, 2) }}</th>
            <th class="text-right font-weight-bold">
              {{ formatNumber(maxMarginDivided, 2) }}
            </th>
            <th class="fw-bold"><i class="bi bi-medium"></i></th>
            <th class="fw-bold">
              <i @click="combinedChartRoute" class="bi bi-graph-up-arrow"></i>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-if="wewaps.length > 0" class="container-fluid my-3">
    <div class="header">
      <h3>Dashboard vwaps</h3>
    </div>
    <table class="table table-bordered custom-table table-hover">
      <thead>
        <tr>
          <th scope="col">SR.No</th>
          <th scope="col" @click="sort2('index_symbol')">index symbol</th>
          <th scope="col" @click="sort2('machine')">machine</th>
          <th scope="col" @click="sort2('exchange_time')">exchange time</th>
          <th scope="col" @click="sort2('event_time')">event time</th>
          <th scope="col" @click="sort2('feed_value')">feed value</th>
          <th scope="col" @click="sort2('previous_day_close')">
            previous day close
          </th>
          <!-- <th scope="col" @click="sort2('ltp_value')">ltp value</th>
          <th scope="col" @click="sort2('vwap_value')">vwap value</th> -->
          <th scope="col" @click="sort2('turnover_during_vwap')">
            turnover during vwap
          </th>
          <th scope="col" @click="sort2('day_turnover')">day turnover</th>
          <th scope="col">chart</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(wewap, index) in sortedWewap" :key="wewap.id">
          <td>{{ index + 1 }}</td>
          <td>{{ wewap.index_symbol }}</td>
          <td>{{ wewap.machine }}</td>
          <td>{{ formatTime(wewap.exchange_time, 0) }}</td>
          <td>{{ formatTime(wewap.event_time, 0) }}</td>
          <td>{{ formatNumber(wewap.feed_value, 2) }}</td>
          <td>{{ formatNumber(wewap.previous_day_close, 2) }}</td>
          <!-- <td>{{ formatNumber(wewap.ltp_value, 2) }}</td> -->
          <!-- <td>{{ formatNumber(wewap.vwap_value, 2) }}</td> -->
          <td>{{ formatNumber(wewap.turnover_during_vwap, 2) }}</td>
          <td>{{ formatNumber(wewap.day_turnover, 2) }}</td>
          <td>
            <router-link :to="`${wewap.index_symbol}/chart/`">
              <i class="bi bi-graph-up-arrow"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import { darkModeMixin } from "@/darkModeMixin.js";

export default {
  name: "HomeView",
  setup() {
    // Get toast interface
    const toast = useToast();
    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      logs: [],
      previous_log_strat: [],
      isFetchingLogs: false,
      new_log_strat: [],
      sortKey: "name",
      wewaps: [],
      team_tab: null,
      process_type: "strategy",
      sortKey2: "name",
      sortDirection: 1,
      sortDirection2: 1,
      intervalLogs: null,
      intervalWewaps: null,
      interval_max_margin: null,
      currentTime: new Date(),
      visibleLogs: [],
      max_margin: "0",
      start_from: "0",
      visibleLogsglobal: [],
      searchbar: false,
      deselectedStrategies: (
        sessionStorage.getItem("reqdlogs") ||
        localStorage.getItem("reqdlogs") ||
        ""
      ).split(","),
      strategy_list_home_page: (
        sessionStorage.getItem("reqdlogs") ||
        localStorage.getItem("reqdlogs") ||
        ""
      ).split(","),
      searchStrats: "",
      team_list: [
        "quant",
        "quant0",
        "quant1",
        // "quant2",
        "quant3",
        "quant4",
        "quant5",
        "okhla_desk",
      ],
    };
  },
  mixins: [darkModeMixin],
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
    this.process_type = localStorage.getItem("active_process_type");
    this.team_tab = localStorage.getItem("active_team_tab");
    this.team_tab = this.team_tab == "null" ? null : this.team_tab;
    this.process_type = this.process_type == "null" ? null : this.process_type;
    this.getLogs1st();
    this.getWewaps();
    this.get_max_margin();
    this.intervalLogs = setInterval(this.getLogs, 1000);
    this.intervalWewaps = setInterval(this.getWewaps, 10000);
    this.interval_max_margin = setInterval(this.get_max_margin, 10000);
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
    this.searchStrats = this.$store.state.search;
  },
  beforeUnmount() {
    clearInterval(this.intervalLogs);
    clearInterval(this.intervalWewaps);
    clearInterval(this.interval_max_margin);
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  computed: {
    maxMarginDivided() {
      return this.max_margin / 100000;
    },
    sortedWewap() {
      return this.wewaps.slice().sort((a, b) => {
        if (isNaN(a[this.sortKey2]) || isNaN(b[this.sortKey2])) {
          // compare as strings
          if (a[this.sortKey2] < b[this.sortKey2]) {
            return -1 * this.sortDirection2;
          }
          if (a[this.sortKey2] > b[this.sortKey2]) {
            return 1 * this.sortDirection2;
          }
        } else if (
          Number.isInteger(a[this.sortKey2]) ||
          Number.isInteger(b[this.sortKey2])
        ) {
          // compare as integers
          const intA = parseInt(a[this.sortKey2]);
          const intB = parseInt(b[this.sortKey2]);
          if (intA < intB) {
            return -1 * this.sortDirection2;
          }
          if (intA > intB) {
            return 1 * this.sortDirection2;
          }
        } else {
          // compare as floats
          const floatA = parseFloat(a[this.sortKey2]);
          const floatB = parseFloat(b[this.sortKey2]);
          if (floatA < floatB) {
            return -1 * this.sortDirection2;
          }
          if (floatA > floatB) {
            return 1 * this.sortDirection2;
          }
        }
        return 0;
      });
    },
    sortedPnl() {
      return this.logs
        .slice()
        .filter((log) => this.visibleLogs.includes(log.name))
        .sort((a, b) => {
          if (isNaN(a[this.sortKey]) || isNaN(b[this.sortKey])) {
            // compare as strings
            if (a[this.sortKey] < b[this.sortKey]) {
              return -1 * this.sortDirection;
            }
            if (a[this.sortKey] > b[this.sortKey]) {
              return 1 * this.sortDirection;
            }
          } else if (
            Number.isInteger(a[this.sortKey]) ||
            Number.isInteger(b[this.sortKey])
          ) {
            // compare as integers
            const intA = a[this.sortKey];
            const intB = b[this.sortKey];
            if (intA < intB) {
              return -1 * this.sortDirection;
            }
            if (intA > intB) {
              return 1 * this.sortDirection;
            }
          } else {
            // compare as floats
            const floatA = parseFloat(a[this.sortKey]);
            const floatB = parseFloat(b[this.sortKey]);
            if (floatA < floatB) {
              return -1 * this.sortDirection;
            }
            if (floatA > floatB) {
              return 1 * this.sortDirection;
            }
          }
          return 0;
        });
    },
    totalNetPnl() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.net_pnl);
        }
        return total;
      }, 0);
    },
    totalRealised() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.realised);
        }
        return total;
      }, 0);
    },
    totalUnrealised() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.unrealised);
        }
        return total;
      }, 0);
    },
    totalCost() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.cost);
        }
        return total;
      }, 0);
    },
    totalTurnover() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.turnover);
        }
        return total;
      }, 0);
    },
    totalStrikeTurnover() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.strike_turnover);
        }
        return total;
      }, 0);
    },
    totalNetExposure() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.net_exposure);
        }
        return total;
      }, 0);
    },
    totalGrossExposure() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.gross_exposure);
        }
        return total;
      }, 0);
    },
    totalOpenLongExposure() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.open_long_exposure);
        }
        return total;
      }, 0);
    },
    totalOpenShortExposure() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.open_short_exposure);
        }
        return total;
      }, 0);
    },
    totalNetOpenAmount() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.net_open_amount);
        }
        return total;
      }, 0);
    },
    totalGrossOpenAmount() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.gross_open_amount);
        }
        return total;
      }, 0);
    },
    totalMargin() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.margin);
        }
        return total;
      }, 0);
    },
    totalmaxMargin() {
      return this.logs.reduce((total, log) => {
        if (this.visibleLogs.includes(log.name)) {
          return total + parseFloat(log.max_margin);
        }
        return total;
      }, 0);
    },
    filteredStrategies() {
      return this.deselectedStrategies.filter((strategy) => strategy !== "");
    },
  },
  methods: {
    selectTeamFilter(team, process_type) {
      this.team_tab = team ?? null;
      this.process_type = process_type ?? null;
      localStorage.setItem("active_team_tab", this.team_tab);
      localStorage.setItem("active_process_type", this.process_type);
      setTimeout(() => {
        this.getLogs();
        this.get_max_margin(true);
      }, 500);
    },
    combinedChartRoute() {
      const routeObject = { path: "combined_chart/" };

      routeObject.query = {
        team_name: this.team_tab,
        process_type: this.process_type,
      };
      this.$router.push(routeObject);
    },
    combinedIndPnlChartRoute() {
      const routeObject = { path: "combined-ind-pnl/" };

      routeObject.query = {
        team_name: this.team_tab,
        process_type: this.process_type,
      };

      this.$router.push(routeObject);
    },
    handleKeyDown(event) {
      // Check if Shift and S keys are pressed
      if (event.shiftKey && event.key === "S") {
        // Prevent the default action of the keypress event
        event.preventDefault();

        // Focus on the input field
        let searchStrats_id = document.getElementById("searchStrats");
        if (searchStrats_id) {
          searchStrats_id.focus();
        }
      }
    },
    delayedLoopTableAndStore(log_ins) {
      if (
        !this.deselectedStrategies.includes(log_ins.name) &&
        log_ins.name != "" &&
        log_ins.name != null
      ) {
        this.deselectedStrategies.push(log_ins.name);
      }
      sessionStorage.setItem("reqdlogs", this.deselectedStrategies);
      localStorage.setItem("reqdlogs", this.deselectedStrategies);
      this.max_margin = "0";
      this.start_from = "0";
      if (this.visibleLogs.includes(log_ins.name)) {
        this.visibleLogs = this.visibleLogs.filter(
          (name) => name !== log_ins.name
        );
      } else {
        this.visibleLogs.push(log_ins.name);
      }
    },
    loopTableAndStore() {
      // Select the table rows
      var tableRows = document.querySelectorAll(".strats tr");
      this.visibleLogsglobal = [];
      this.strategy_list_home_page = [];
      for (let i = 1; i < tableRows.length - 1; i++) {
        // Find the data in the second column (index 1) and push to the array
        var row = tableRows[i];
        var rowData = row.cells[1].textContent;
        if (rowData != "") {
          this.visibleLogsglobal.push(rowData);
          this.strategy_list_home_page.push(rowData);
        }
      }

      // Log the array togetLogs1st the console (you can use it as needed)
      console.log(this.visibleLogsglobal);
      sessionStorage.setItem("reqdlogs", this.visibleLogsglobal);
      localStorage.setItem("reqdlogs", this.visibleLogsglobal);
      this.max_margin = "0";
      this.start_from = "0";
    },
    getLogs1st() {
      // Store the count of previous logs
      const previousLogsCount = this.logs.length;
      this.previous_log_strat = [];
      this.previous_log_strat = [...this.new_log_strat];
      var strategy_list_home_page_get = (
        sessionStorage.getItem("reqdlogs") ||
        localStorage.getItem("reqdlogs") ||
        ""
      ).split(",");
      var strategy_list_local_home = strategy_list_home_page_get.filter(
        (item) => item !== ""
      );
      axios
        .post("api/logs/", {
          deselected_strats: strategy_list_local_home,
          search_bar_value: "",
          team_name: this.team_tab,
          process_type: this.process_type,
        })
        .then((response) => {
          this.logs = response.data.data;
          this.logs?.map((log) => {
            if (log?.machine) {
              log.team =
                log?.machine?.split("@")?.length > 0
                  ? log?.machine?.split("@")[0]
                  : log?.machine;
              log.machine =
                log?.machine?.split("@")?.length > 0
                  ? log?.machine?.split("@")[1]
                  : log?.machine;
            }
          });
          this.strategy_list_home_page = response.data.strategy_list;
          this.new_log_strat = response.data.strategy_list;
          this.$store.commit("setStrategyList", response.data.strategy_list);

          // console.log(response.data);
          // Add all log names to visibleLogs array
          this.visibleLogs = this.logs.map((log) => log.name);
          // Get the count of new logs
          const newLogsCount = response.data.data.length;
          if (previousLogsCount === 0) {
            // Initial fetch, don't display alerts
            return;
          } else if (newLogsCount > previousLogsCount) {
            // Get the last added log
            // const addedLogs = response.data.data.slice(previousLogsCount);
            // addedLogs.forEach((addedLog) => {
            //   // const message = `Strategy ${addedLog.name} Started`;
            //   // Display the toast message using vue-toastification
            //   // this.toast.success(message);
            // });
          }
          if (newLogsCount != previousLogsCount) {
            this.max_margin = "0";
            this.start_from = "0";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLogs() {
      if (!this.isFetchingLogs) {
        // Store the count of previous logs
        var previousLogsCount = this.logs.length;
        this.previous_log_strat = [];
        this.previous_log_strat = [...this.new_log_strat];
        var strategy_list_home_page_get = (
          sessionStorage.getItem("reqdlogs") ||
          localStorage.getItem("reqdlogs") ||
          ""
        ).split(",");
        var strategy_list_local_home = strategy_list_home_page_get.filter(
          (item) => item !== ""
        );
        this.isFetchingLogs = true;

        axios
          .post(
            "api/logs/",
            {
              deselected_strats: strategy_list_local_home,
              search_bar_value: this.searchStrats || "",
              team_name: this.team_tab,
              process_type: this.process_type,
            },
            { timeout: 5000 }
          )
          .then((response) => {
            this.isFetchingLogs = false;
            this.logs = response.data.data;
            if (this.logs?.length > 0) {
              this.logs?.map((log) => {
                if (log?.machine) {
                  log.team =
                    log?.machine?.split("@")?.length > 0
                      ? log?.machine?.split("@")[0]
                      : log?.machine;
                  log.machine =
                    log?.machine?.split("@")?.length > 0
                      ? log?.machine?.split("@")[1]
                      : log?.machine;
                }
              });
            }
            console.log(this.logs);
            this.strategy_list_home_page = response.data.strategy_list;
            this.new_log_strat = response.data.strategy_list;
            this.$store.commit("setStrategyList", response.data.strategy_list);
            const newLogsCount = response.data.data.length;
            console.log(this.searchbar);
            if (this.searchbar) {
              const allLogNames = this.logs.map((log) => log.name);
              // Set all logs as visible
              this.visibleLogs = allLogNames;
              this.searchbar = false;
              return;
            }
            if (newLogsCount > previousLogsCount) {
              // Get the last added log
              const addedLogs = response.data.data.slice(previousLogsCount);
              // addedLogs.forEach((addedLog) => {
              //   const message = `Strategy ${addedLog.name} Started`;
              //   // Display the toast message using vue-toastification
              //   this.toast.success(message);
              // });
              // Add only the new log names to visibleLogs array
              const newLogNames = addedLogs.map((log) => log.name);
              this.visibleLogs = this.visibleLogs.concat(newLogNames);
            }
            if (newLogsCount != previousLogsCount) {
              this.max_margin = "0";
              this.start_from = "0";
            }
            this.visibleLogs = response.data?.strategy_list;
          })
          .catch((error) => {
            this.isFetchingLogs = false;
            console.log(error);
          });
      }
    },

    get_max_margin(team_change = false) {
      var strategy_list = (
        sessionStorage.getItem("reqdlogs") ||
        localStorage.getItem("reqdlogs") ||
        ""
      ).split(",");
      let mm = this.max_margin;
      // if (
      //   sessionStorage.getItem("reqdlogs") == null &&
      //   localStorage.getItem("reqdlogs") == null
      // ) {
      //   mm = "0";
      //   this.start_from = "0";
      // }

      if (this.new_log_strat?.length != this.previous_log_strat?.length) {
        mm = "0";
        this.start_from = "0";
      }
      var strategy_list_local = strategy_list.filter((item) => item !== "");
      if (team_change) {
        mm = "0";
        this.start_from = "0";
      }
      axios
        .post("api/max-margin/", {
          last_max_margin: mm,
          last_id_recieved: this.start_from,
          strategy_list: strategy_list_local,
          team_name: this.team_tab,
          process_type: this.process_type,
          search_bar_value: this.searchStrats || "",
        })
        .then((response) => {
          this.max_margin = response.data.max_margin;
          this.start_from = response.data.last_id_recieved;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatNumber(num, des) {
      return parseFloat(num).toFixed(des);
    },
    formatTime(num, des) {
      console.log(des);
      if (num) {
        let time = num.split(".");
        return time.length > 0 ? time[0] : null;
      }
      return null;
    },
    sort(key) {
      if (key === this.sortKey) {
        this.sortDirection *= -1;
      } else {
        this.sortKey = key;
        this.sortDirection = 1;
      }
    },
    rowClass(log) {
      const fiveMinutesInMilliseconds = 5 * 60 * 1000;
      const currentYear = this.currentTime.getFullYear();
      const currentMonth = this.currentTime.getMonth();
      const currentDate = this.currentTime.getDate();
      const updatedTime = new Date(
        currentYear,
        currentMonth,
        currentDate,
        log.time.slice(0, 2),
        log.time.slice(2, 4),
        log.time.slice(4)
      );
      return this.currentTime - updatedTime > fiveMinutesInMilliseconds
        ? "red"
        : "";
    },
    getWewaps() {
      axios
        .get("api/vwaps/")
        .then((response) => {
          this.wewaps = response.data;
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sort2(key) {
      if (key === this.sortKey2) {
        this.sortDirection2 *= -1;
      } else {
        this.sortKey2 = key;
        this.sortDirection2 = 1;
      }
    },
    vwaprowClass(wewap) {
      const diff = Math.abs(wewap.feed_value - wewap.ltp_value);
      const relativeDiff = Math.abs(
        (wewap.feed_value - wewap.ltp_value) / wewap.ltp_value
      );
      const fiveMinutesInMilliseconds = 5 * 60 * 1000;
      const currentYear = this.currentTime.getFullYear();
      const currentMonth = this.currentTime.getMonth();
      const currentDate = this.currentTime.getDate();
      const updatedTime = new Date(
        currentYear,
        currentMonth,
        currentDate,
        wewap.event_time.slice(0, 2),
        wewap.event_time.slice(2, 4),
        wewap.event_time.slice(4)
      );
      if (
        diff >= 5.0 ||
        relativeDiff > 0.0005 ||
        this.currentTime - updatedTime > fiveMinutesInMilliseconds
      ) {
        return "highlighted-row";
      }
      return "";
    },
    toggleLogVisibility(logName) {
      if (this.visibleLogs.includes(logName)) {
        this.visibleLogs = this.visibleLogs.filter((name) => name !== logName);
      } else {
        this.visibleLogs.push(logName);
      }
    },
    resetStrats() {
      sessionStorage.removeItem("reqdlogs");
      localStorage.removeItem("reqdlogs");
      location.reload();
    },
    searchStratsFn() {
      this.$store.commit("setSearchValue", this.searchStrats);
      this.max_margin = "0";
      this.start_from = "0";
      if (this.searchStrats != "" && this.searchStrats.length > 2) {
        this.searchbar = true;
      } else {
        this.searchbar = false;
      }
    },
    showDeSelectedStrategies(strategy) {
      let de_select_stratgy =
        (
          localStorage.getItem("reqdlogs") || sessionStorage.getItem("reqdlogs")
        ).split(",") || [];
      console.log(de_select_stratgy);
      if (de_select_stratgy.includes(strategy)) {
        let index = de_select_stratgy.indexOf(strategy);
        de_select_stratgy.splice(index, 1);
        this.deselectedStrategies.splice(index, 1);
        localStorage.setItem("reqdlogs", de_select_stratgy);
        sessionStorage.setItem("reqdlogs", de_select_stratgy);
        if (!this.visibleLogs.includes(strategy)) {
          this.visibleLogs.push(strategy);
        }
      }
    },
  },
  watch: {
    searchStrats() {
      this.max_margin = "0";
      this.start_from = "0";
    },
  },
  created() {
    this.searchStrats = "";
  },
};
</script>
<style>
body.dark-mode,
body.dark-mode .offcanvas {
  background-color: rgb(36, 36, 36) !important;
  color: rgb(173, 190, 210) !important;
  /* Add any other styles for dark mode */
}

body.dark-mode .custom-table {
  background-color: rgb(28, 28, 33) !important;
  color: #fff !important;
}

body.dark-mode .pace-navbar {
  background-color: rgb(28, 28, 33);
}

body.dark-mode .header h2::after,
body.dark-mode .header h3::after,
.header h2::before,
.header h3::before {
  background-color: rgb(167, 50, 42);
}

body.dark-mode a {
  color: rgb(173, 190, 210) !important;
}

.btn.btn-toggle-dark-mode {
  background-color: #333 !important;
  color: #fff !important;
}

body.dark-mode .custom-table th {
  vertical-align: middle !important;
  text-align: center !important;
  font-size: 15px !important;
  color: rgb(173, 190, 210) !important;
  padding: 0.6rem 0.2rem !important;
  background-color: rgb(28, 28, 33) !important;
  font-weight: 900;
}

body.dark-mode table {
  border-color: rgb(54, 54, 54) !important;
}

table {
  border-color: #dee2e6 !important;
}

body.dark-mode .custom-table td {
  /* Add your dark mode table styles here */
  background-color: rgb(28, 28, 33);
  color: rgb(173, 190, 210) !important;
  font-weight: 600;
}

body.dark-mode .form-check-input[type="checkbox"],
body.dark-mode .form-check-input[type="radio"] {
  background-color: rgb(167, 50, 42) !important;
  border-radius: 80% !important;
  border-color: rgb(167, 50, 42) !important;
}

body.dark-mode .custom-table tr.highlighted-row td {
  color: #c16969 !important/* Your preferred color for highlighted rows in dark mode */;
}

body.dark-mode .custom-table tr.red td {
  color: #c16969 !important/* Your preferred color for red rows */;
}

.header {
  display: flex;
  align-items: center;
}
.header {
  display: flex;
  align-items: center;
}

.btn {
  padding: 8px 16px;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn.btn-outline-custom {
  color: #f3756c;
  border-color: #f3756c;
  margin-right: 1%;
}

.btn.btn-outline-custom:hover,
.btn.btn-outline-custom.active {
  background-color: #f3756c;
  color: #fff;
}

body.dark-mode .table-hover tbody tr:hover td,
body.dark-mode .table-hover tbody tr:hover th {
  background-color: #555 !important; /* Even darker background color on hover in dark mode */
}
.margin-left {
  width: 21rem;
}
.custom-table td .bg-red {
  background-color: rgb(167, 50, 42) !important;
}
</style>
