<template>
  <nav v-if="isLoggedIn && !isLogin" class="container-fluid navbar pace-navbar">
    <div class="float-start">
      <router-link to="/">
        <a class="mx-3"><i class="bi bi-box-seam"></i> dashboard</a>
      </router-link>
      <!-- <router-link :to="{ name: 'ProcessView' }">
        <a class="mx-3">Processes</a>
      </router-link> -->
      <router-link :to="{ name: 'ProcessViewV1' }">
        <a class="mx-3">Processes</a>
      </router-link>
      <!-- <template v-if="has_permission2">
        <router-link :to="{ name: 'CtclView' }" @click="reload">
          <a class="mx-3">Ctcl</a>
        </router-link>
      </template> -->
      <template v-if="has_permission2">
        <router-link :to="{ name: 'CTCLSV1' }">
          <a class="mx-3">CTCLS</a>
        </router-link>
      </template>
      <router-link :to="{ name: 'Recon' }" v-if="has_permission">
        <a class="mx-3"><i class="bi bi-clock-history"></i> Recon</a>
      </router-link>
      <template v-if="has_permission3">
        <router-link :to="{ name: 'CTCLSHistory' }">
          <a class="mx-3">CTCLS History</a>
        </router-link>
      </template>
      <!-- <router-link :to="{ name: 'UniversalData' }">
        <a class="mx-3"><i class="bi bi-clock-history"></i> Universal</a>
      </router-link> -->
      <!-- <router-link to="/monitor">
        <a class="mx-3"><i class="bi bi-box-seam"></i> monitors</a>
      </router-link> -->
      <!-- <router-link to="/logs">
        <a><i class="bi bi-terminal"></i> logs</a>
      </router-link> -->
    </div>
    <div class="float-end">
      <a v-if="isLoggedIn" class="outline-btn mx-2 time-btn disabled-btn">
        <i class="bi bi-calendar-fill"></i> {{ istTime?.time }}
        {{ istTime?.day }} {{ istTime?.date }}</a
      >
      <a v-if="isLoggedIn" class="outline-btn time-btn" v-on:click="logout()"
        >Logout</a
      >
    </div>
  </nav>
  <router-view />
</template>
<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      isLogin: true,
      has_permission: false,
      has_permission2: false,
      istTime: "",
      istTimeInterval: null,
      has_permission3: false,
    };
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
    const access_token = this.$store.state.access_token;
    if (access_token) {
      axios.defaults.headers.common["Authorization"] = "Token " + access_token;
    } else {
      // No token, clear the Authorization header
      axios.defaults.headers.common["Authorization"] = "  ";
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.isHomepage = to.name === "home";
      next();
    });
    this.$router.beforeEach((to, from, next) => {
      this.isLogin = to.name === "login";
      next();
    });
  },
  mounted() {
    const email = localStorage.getItem("email");
    this.has_permission = [
      "anilgupta@pacefin.in",
      "ayushtulsyan@pacefin.in",
      "shreyansh@pacefin.in",
      "rahuldubey@pacefin.in",
      "jatinpandey@pacefin.in",
    ].includes(email);
    this.has_permission2 = [
      "anilgupta@pacefin.in",
      "ayushtulsyan@pacefin.in",
      "shreyansh@pacefin.in",
      "rahuldubey@pacefin.in",
      "jatinpandey@pacefin.in",
      "nikhilgupta@pacefin.in",
      "japjeet@pacefin.in",
    ].includes(email);

    this.updateISTTime();
    // this.istTimeInterval = setInterval(this.updateISTTime, 1000);
    this.has_permission3 = [
      "anilgupta@pacefin.in",
      "ayushtulsyan@pacefin.in",
      "shreyansh@pacefin.in",
      "rahuldubey@pacefin.in",
      "jatinpandey@pacefin.in",
      "rishabhgupta@pacefin.in",
    ].includes(email);
  },
  beforeUnmount() {
    clearInterval(this.istTimeInterval);
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.access_token;
    },
  },
  methods: {
    logout() {
      axios
        .post("/auth/logout/")
        .then(() => {
          // Handle successful logout
          localStorage.removeItem("access_token");
          localStorage.removeItem("reqdlogs");
          sessionStorage.removeItem("reqdlogs");
          localStorage.removeItem("email");
          this.$store.commit("setAccesstoken", null);
          localStorage.clear();
          clearInterval(this.istTimeInterval);
          this.$router.push("/login");
        })
        .catch((error) => {
          // Handle error
          console.log(error);
        });
    },
    reload() {
      if (this.$route.fullPath != "/ctcl" && this.$route.name == "CtclView") {
        this.$router.push("/ctcl");
        setTimeout(() => {
          location.reload();
        }, 10);
      }
    },
    updateISTTime() {
      axios
        .get("ctcl/ist-datetime/")
        .then((response) => {
          this.istTime = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css?family=Ubuntu+Mono");
/* Universal section start */
body {
  font-family: "Quicksand";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #415a77 !important;
  background-color: #edf2fb !important;
  letter-spacing: 0.6px;
}
a {
  text-decoration: none !important;
  color: #415a77 !important;
  cursor: pointer !important;
}
a:hover {
  color: #f3756c !important;
}
/* Universal section end */
/* Navbar section start */
.pace-navbar {
  font-size: 18px;
  background-color: #fff;
  padding: 10px 10px !important;
}
.outline-btn {
  font-size: 18px;
  border-style: solid;
  border-width: 2px;
  border-color: #415a77;
  border-radius: 2px;
  padding: 2px 9px;
  letter-spacing: 1px;
}
.outline-btn:hover {
  border-color: #f3756c !important;
  color: #f3756c !important;
}
/* Navbar section end */
/* Header and table css start */
.header h2 {
  font-family: "Inconsolata", monospace;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 10px;
  position: relative;
}
.header h2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #f3756c;
}
.header h2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 228px;
  background-color: #f3756c;
}
.header h3 {
  font-family: "Inconsolata", monospace;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 10px;
  position: relative;
}
.header h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #f3756c;
}
.header h3:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 170px;
  background-color: #f3756c;
}
.custom-table {
  background-color: #fff;
}
.custom-table th,
.custom-table td {
  padding: 0.6rem 0.2rem !important;
  vertical-align: middle !important;
  text-align: center !important;
  font-size: 15px !important;
  color: #415a77 !important;
}
.form-check-input[type="checkbox"],
.form-check-input[type="radio"] {
  border-radius: 80% !important;
  border-color: #f3756c !important;
  background-color: #f3756c !important;
  width: 1.3em;
  height: 1.3em;
}
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #edf2fb;
}
.custom-table tr.red td {
  color: red !important;
}
.custom-table tr.highlighted-row td {
  color: red !important;
}
body.dark-mode .table-hover tbody tr:hover .bg-red {
  background-color: rgb(167, 50, 42) !important;
}
body.dark-mode .table-hover tbody tr .bg-red {
  background-color: rgb(167, 50, 42) !important;
}
.table-hover tbody tr .bg-red {
  background-color: rgb(167, 50, 42) !important;
  color: #fff !important;
}
/* Header and table css end */
.time-btn {
  font-size: 1rem;
  padding: 0.4rem;
  border-radius: 0.6rem;
}
.disabled-btn {
  pointer-events: none;
}
</style>
